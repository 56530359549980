/* Museo Sans Cyrl (for se-common) */
@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 300;
  font-style: normal;
  src: local('Museo Sans 300'),
    url('../fonts/MuseoSans/MuseoSans-300.otf') format('opentype');
}

/* Bold font on eiq grid (special column) */
/* @font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 500;
  font-style: normal;
  src: local('Museo Sans 500'),
    url('../fonts/MuseoSans/MuseoSans_500.otf') format('opentype');
} */

@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 700;
  font-style: normal;
  src: local('Museo Sans 700'),
    url('../fonts/MuseoSans/MuseoSans_700.otf') format('opentype');
}

/* Museo Sans */
@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Museo Sans 100'),
    url('../fonts/MuseoSans/MuseoSans-100.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Museo Sans 300'),
    url('../fonts/MuseoSans/MuseoSans-300.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Museo Sans 300 Italic'),
    url('../fonts/MuseoSans/MuseoSans-300Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Museo Sans 500'),
    url('../fonts/MuseoSans/MuseoSans_500.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Museo Sans 500 Italic'),
    url('../fonts/MuseoSans/MuseoSans_500_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Museo Sans 700'),
    url('../fonts/MuseoSans/MuseoSans_700.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Museo Sans 700 Italic'),
    url('../fonts/MuseoSans/MuseoSans-700Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Museo Sans 900'),
    url('../fonts/MuseoSans/MuseoSans_900.otf') format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: italic;
  font-weight: 900;
  src: local('Museo Sans 900 Italic'),
    url('../fonts/MuseoSans/MuseoSans-900Italic.otf') format('opentype');
}

/* Source Sans Pro */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Sans Pro Extra-light'),
    url('../fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: local('Source Sans Pro Extra-Light Italic'),
    url('../fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro light'),
    url('../fonts/SourceSansPro/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'),
    url('../fonts/SourceSansPro/SourceSansPro-LightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'),
    url('../fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'),
    url('../fonts/SourceSansPro/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro Semi Bold'),
    url('../fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro Semi Bold Italic'),
    url('../fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'),
    url('../fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'),
    url('../fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Sans Pro Black'),
    url('../fonts/SourceSansPro/SourceSansPro-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Source Sans Pro Black Italic'),
    url('../fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf')
      format('truetype');
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'),
    url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'),
    url('../fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'),
    url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Regular Italic'),
    url('../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semi Bold'),
    url('../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans Semi Bold Italic'),
    url('../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'),
    url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'),
    url('../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extra Bold'),
    url('../fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans Extra Bold Italic'),
    url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

/* Open Sans Condensed */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Condensed Light'),
    url('../fonts/OpenSansCondensed/OpenSansCondensed-Light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Condensed Light Italic'),
    url('../fonts/OpenSansCondensed/OpenSansCondensed-LightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Condensed Bold'),
    url('../fonts/OpenSansCondensed/OpenSansCondensed-Bold.ttf')
      format('truetype');
}

/* Futura Std */

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 500;
  src: local('Futura Std Medium'),
    url('../fonts/FuturaStd/FuturaStd-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Std Bold'),
    url('../fonts/FuturaStd/FuturaStd-Bold.otf') format('opentype');
}

/* Minion Pro */

@font-face {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Minion Pro Regular'),
    url('../fonts/MinionPro/MinionPro-Regular.otf') format('opentype');
}

/* Gill Sans */

@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Gill Sans'),
    url('../fonts/GillSans/GILLSANSSTD.OTF') format('opentype');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Gill Sans Light'),
    url('../fonts/GillSans/GILLSANSSTD-LIGHT.OTF') format('opentype');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Gill Sans Italic'),
    url('../fonts/GillSans/GILLSANSSTD-ITALIC.OTF') format('opentype');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Gill Sans Bold Italic'),
    url('../fonts/GillSans/GILLSANSSTD-BOLDITALIC.OTF') format('opentype');
}

@font-face {
  font-family: 'Gill Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Gill Sans Bold'),
    url('../fonts/GillSans/GILLSANSSTD-BOLD.OTF') format('opentype');
}

/* Montserrat */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat'),
    url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat'),
    url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat'),
    url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

/* Roboto */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'),
    url('../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'),
    url('../fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'),
    url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'),
    url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'),
    url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Regular Italic'),
    url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'),
    url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'),
    url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'),
    url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'),
    url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'),
    url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'),
    url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

/* Allianz Neo */

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 300;
  src: local('Allianz Neo Light'),
    url('../fonts/AllianzNeo/AllianzNeo-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: italic;
  font-weight: 300;
  src: local('Allianz Neo Light Italic'),
    url('../fonts/AllianzNeo/AllianzNeo-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 300;
  font-stretch: condensed;
  src: local('Allianz Neo Condensed Light'),
    url('../fonts/AllianzNeo/AllianzNeo-CondensedLight.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 400;
  src: local('Allianz Neo'),
    url('../fonts/AllianzNeo/AllianzNeo-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: italic;
  font-weight: 400;
  src: local('Allianz Neo Italic'),
    url('../fonts/AllianzNeo/AllianzNeo-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 400;
  font-stretch: condensed;
  src: local('Allianz Neo Condensed'),
    url('../fonts/AllianzNeo/AllianzNeo-Condensed.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 600;
  src: local('Allianz Neo Semi Bold'),
    url('../fonts/AllianzNeo/AllianzNeo-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: italic;
  font-weight: 600;
  src: local('Allianz Neo Semi Bold Italic'),
    url('../fonts/AllianzNeo/AllianzNeo-SemiBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 700;
  src: local('Allianz Neo Bold'),
    url('../fonts/AllianzNeo/AllianzNeo-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: italic;
  font-weight: 700;
  src: local('Allianz Neo Bold Italic'),
    url('../fonts/AllianzNeo/AllianzNeo-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 700;
  font-stretch: condensed;
  src: local('Allianz Neo Condensed Bold'),
    url('../fonts/AllianzNeo/AllianzNeo-CondensedBold.otf') format('opentype');
}

/* Albert Pro */

@font-face {
  font-family: 'Albert Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Albert Pro Bold'),
    url('../fonts/AlbertPro/AlbertPro-Bold.otf') format('opentype');
}

/* Nunito */

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: local('Nunito'),
    url('../fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: local('Nunito Italic'),
    url('../fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf')
      format('truetype');
}

/* Graphik */

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 900;
  src: local('Graphik Black'),
    url('../fonts/Graphik/Graphik-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 900;
  src: local('Graphik Black Italic'),
    url('../fonts/Graphik/Graphik-BlackItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 700;
  src: local('Graphik Bold'),
    url('../fonts/Graphik/Graphik-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 700;
  src: local('Graphik Bold Italic'),
    url('../fonts/Graphik/Graphik-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 200;
  src: local('Graphik Extra-light'),
    url('../fonts/Graphik/Graphik-Extralight.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 200;
  src: local('Graphik Extra-Light Italic'),
    url('../fonts/Graphik/Graphik-ExtralightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 300;
  src: local('Graphik light'),
    url('../fonts/Graphik/Graphik-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 300;
  src: local('Graphik Light Italic'),
    url('../fonts/Graphik/Graphik-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  src: local('Graphik Medium'),
    url('../fonts/Graphik/Graphik-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 500;
  src: local('Graphik Medium Italic'),
    url('../fonts/Graphik/Graphik-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  src: local('Graphik Regular'),
    url('../fonts/Graphik/Graphik-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 400;
  src: local('Graphik Regular Italic'),
    url('../fonts/Graphik/Graphik-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  src: local('Graphik Semi Bold'),
    url('../fonts/Graphik/Graphik-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 600;
  src: local('Graphik Semi Bold Italic'),
    url('../fonts/Graphik/Graphik-SemiboldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  src: local('Graphik Super'),
    url('../fonts/Graphik/Graphik-Super.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 600;
  src: local('Graphik Super Italic'),
    url('../fonts/Graphik/Graphik-SuperItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 100;
  src: local('Graphik Thin'),
    url('../fonts/Graphik/Graphik-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 100;
  src: local('Graphik Thin Italic'),
    url('../fonts/Graphik/Graphik-ThinItalic.otf') format('opentype');
}

/* Hellix */

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 900;
  src: local('Hellix Black'),
    url('../fonts/Hellix/Hellix-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 700;
  src: local('Hellix Bold'),
    url('../fonts/Hellix/Hellix-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 700;
  src: local('Hellix Bold Italic'),
    url('../fonts/Hellix/Hellix-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 800;
  src: local('Hellix Extra Bold'),
    url('../fonts/Hellix/Hellix-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 800;
  src: local('Hellix Extra Bold Italic'),
    url('../fonts/Hellix/Hellix-ExtraBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 300;
  src: local('Hellix light'),
    url('../fonts/Hellix/Hellix-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 300;
  src: local('Hellix Light Italic'),
    url('../fonts/Hellix/Hellix-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 500;
  src: local('Hellix Medium'),
    url('../fonts/Hellix/Hellix-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 500;
  src: local('Hellix Medium Italic'),
    url('../fonts/Hellix/Hellix-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 400;
  src: local('Hellix Regular'),
    url('../fonts/Hellix/Hellix-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 400;
  src: local('Hellix Regular Italic'),
    url('../fonts/Hellix/Hellix-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 600;
  src: local('Hellix Semi Bold'),
    url('../fonts/Hellix/Hellix-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 600;
  src: local('Hellix Semi Bold Italic'),
    url('../fonts/Hellix/Hellix-SemiBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: 100;
  src: local('Hellix Thin'),
    url('../fonts/Hellix/Hellix-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: italic;
  font-weight: 100;
  src: local('Hellix Thin Italic'),
    url('../fonts/Hellix/Hellix-ThinItalic.otf') format('opentype');
}

/* Albert Pro */

@font-face {
  font-family: 'Alda Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Alda Pro Regular'),
    url('../fonts/AldaPro/Alda Pro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Alda Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Alda Pro Italic'),
    url('../fonts/AldaPro/Alda Pro-Regular Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Alda Pro';
  font-style: normal;
  font-weight: 700;
  font-stretch: condensed;
  src: local('Alda Pro Bold'),
    url('../fonts/AldaPro/Alda Pro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Alda Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Alda Pro Bold Italic'),
    url('../fonts/AldaPro/Alda Pro-Bold Italic.otf') format('opentype');
}

/* Helvetica Neue LT Pro */

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue LT Pro Black'),
    url('../fonts/Helvetica/HelveticaNeueLTPro-Bd.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue LT Pro Roman'),
    url('../fonts/Helvetica/HelveticaNeueLTPro-Roman.otf') format('opentype');
}

/* Unbounded */
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 200;
  src: local('Unbounded Extra Light 200'),
    url('../fonts/Unbounded/Unbounded-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 300;
  src: local('Unbounded Light 300'),
    url('../fonts/Unbounded/Unbounded-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 500;
  src: local('Unbounded Medium 500'),
    url('../fonts/Unbounded/Unbounded-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 600;
  src: local('Unbounded SemiBold 600'),
    url('../fonts/Unbounded/Unbounded-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 700;
  src: local('Unbounded Bold 700'),
    url('../fonts/Unbounded/Unbounded-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 800;
  src: local('Unbounded ExtraBold 800'),
    url('../fonts/Unbounded/Unbounded-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 900;
  src: local('Unbounded Black 900'),
    url('../fonts/Unbounded/Unbounded-Black.ttf') format('opentype');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 400;
  src: local('Unbounded 400'),
    url('../fonts/Unbounded/Unbounded-Regular.ttf') format('opentype');
}

/* Soleil */
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 300;
  src: local('Soleil Light 300'),
    url('../fonts/Soleil_Bundle/Soleil-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 400;
  src: local('Soleil 400'),
    url('../fonts/Soleil_Bundle/Soleil-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 600;
  src: local('Soleil SemiBold 600'),
    url('../fonts/Soleil_Bundle/Soleil-Semibold.otf') format('opentype');
}
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 700;
  src: local('Soleil Bold 700'),
    url('../fonts/Soleil_Bundle/Soleil-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 800;
  src: local('Soleil ExtraBold 800'),
    url('../fonts/Soleil_Bundle/Soleil-Extrabold.otf') format('opentype');
}

/* Amplitude */
@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 300;
  src: local('Amplitude Light 300'),
  url('../fonts/Amplitude/Amplitude-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 400;
  src: local('Amplitude Regular 400'),
  url('../fonts/Amplitude/Amplitude-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 600;
  src: local('Amplitude Medium 600'),
  url('../fonts/Amplitude/Amplitude-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 700;
  src: local('Amplitude Bold 700'),
  url('../fonts/Amplitude/Amplitude-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 800;
  src: local('Amplitude Book'),
  url('../fonts/Amplitude/amplitude-book.ttf') format('opentype');
}
