// Override default ieq form-controls because of wrong font load. (ex. font-weight: 500 for controls)

se-form-input {
  input.form-control {
    font-weight: 300 !important;
  }

  .formatted-input span {
    font-weight: 300 !important;
  }
}

se-textarea {
  textarea.form-control {
    font-weight: 300 !important;
  }
}

se-extended-data-list {
  .extended-data-list input[type='text'] {
    font-weight: 300 !important;
  }
}
