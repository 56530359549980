@use 'sass:map';

@import 'variables';

@mixin dynamic-form() {
  $label-width: 15vw;

  .dynamic-form {
    margin-bottom: 0;
    se-dynamic-form-group {
      padding: 0 !important;
      .question {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        padding-bottom: 0;

        > .question-label {
          flex-basis: $label-width;
          font-size: 1.2rem;
        }

        > .element-input,
        > .element-select,
        > .element-datalist,
        > .element-checkbox,
        > .element-textarea,
        > .element-dynamicInputList {
          flex-grow: 1;
          flex-basis: auto;
        }

        > .element-checkbox {
          > .checkbox-list {
            flex-direction: column;

            > .checkbox-item {
              &:first-child {
                margin-top: 0;
              }

              .form-check.checkbox {
                padding-left: 0;
              }
            }
          }
        }

        > .element-toggle .se-toggler {
          display: flex;
          flex-direction: row-reverse;

          > .toggler-label {
            width: $label-width;
            font-size: 1.2rem;
            margin-left: 0;
          }
        }

        > .se-alert-danger {
          flex: 1 1 100%;
          margin-left: $label-width;
        }
      }
    }

    .dynamic-form-footer {
      padding: 0;
    }
  }
}

@mixin custom-search-container() {
  > .grid-heading {
    padding-top: 20px;
    > .row > [class*='col'] > .search-container {
      position: absolute;
      margin-top: -75px;
      right: 0px;
      padding-right: 0px;

      > .input-group {
        > .fa-search {
          font-size: 20px;
          right: 25px;
          bottom: -20px;
          left: auto;
        }

        > input {
          border-radius: 4px;
          border: none;
          color: #9ba8b4;
          font-size: 20px;
          padding: 5px 30px 5px 10px;

          &::placeholder {
            color: #9ba8b4;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            color: #9ba8b4;
          }

          &::-ms-input-placeholder {
            color: #9ba8b4;
          }
        }
      }
    }
  }
}

@mixin custom-modal-footer {
  display: flex;
  justify-content: flex-end;

  > ac-button,
  > se-button {
    &:not(:first-child) {
      display: block;
      margin-left: 15px;
    }
  }
}

@mixin search-container($search-left: true) {
  position: relative;

  > .fa-search {
    position: absolute;
    top: 20%;
    font-size: 1.5rem;
    @if $search-left {
      left: 5px;
    } @else {
      right: 5px;
    }
  }

  ::ng-deep > se-form-input > .input-wrapper > .form-control {
    @if $search-left {
      padding-left: 30px;
    } @else {
      padding-right: 30px;
    }
  }
}

@mixin clear-input-btn {
  background-color: $white;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-color: #ccc;
  border-left: none;
  > .fa-times {
    color: black;
  }
}

@mixin flex-container(
  $justify-content: flex-start,
  $align-items: stretch,
  $flex-direction: row
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
}

@mixin format-notes($height: 37px) {
  word-break: break-all;
  height: $height;
  overflow: hidden;
  color: #000;
}

@mixin modal-header($fontSize: 1.6em) {
  > .title {
    margin: 0;
    font-weight: bold;
    font-size: $fontSize;
  }
}

@mixin header($align-items, $is-modal-header: false) {
  $title-selector: '.page-name';
  $badge-selector: 'ensight-pdf-logo';
  @if $is-modal-header {
    $title-selector: '.modal-title';
    $badge-selector: '.close';
  }

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas: 'a b c';
  align-items: $align-items;
  margin: 0;

  > #{$title-selector} {
    grid-area: b;
    text-align: center;
  }

  > #{$badge-selector} {
    grid-area: c;
    justify-self: end;
  }
}

@mixin required($margin-left: 0.3em, $color: red) {
  content: '*';
  color: $color;
  margin-left: $margin-left;
}

@mixin spinner-holder($position: absolute, $height: 100%, $background-color: rgba(255, 255, 255, 0.7)) {
  background: $background-color;
  position: $position;
  width: 100%;
  height: $height;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .container-holder {
    @include flex-container($flex-direction: column, $justify-content: center, $align-items: center);

    .message {
      font-size: 18px;
      margin-top: 12px;
      font-weight: bold;
    }
  }
}

@mixin trim-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-ellipsis($width: 100%) {
  @include trim-text();
  max-width: 100%;
  width: $width;
}

@mixin chart {
  text {
    font-family: 'Museo Sans', sans-serif;
    font-size: 11px;
    line-height: 1.55;
    letter-spacing: 0.2px;
    text-align: right;
    fill: #444950;
  }

  .nv-axislabel {
    stroke-width: 1;
    stroke: rgba(0, 0, 0, 1);
    font-family: 'Museo Sans', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.55;
    letter-spacing: 1px;
    fill: #a3b3c3;
  }

  .nv-y {
    .domain {
      display: none;
    }
    text {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  .nv-x {
    .tick {
      line {
        display: none;
      }
    }
    text {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  .nv-axis {
    .nv-axisMaxMin {
      text {
        font-weight: normal;
      }
    }
  }

  .nv-groups {
    .nv-group {
      stroke-width: 5 !important;
      fill-opacity: 0.3 !important;

      &.unlimited-line {
        fill: transparent !important;
        stroke: transparent !important;
      }
    }

    &.lineView {
      .nv-group {
        path.nv-area {
          opacity: 0;
          transition: opacity 500ms ease;
        }
      }
    }

    &.areaView {
      .nv-group {
        path.nv-area {
          opacity: 1;
          transition: opacity 500ms ease;
        }
      }
    }
  }
}

@mixin metric-switcher($isTab: false) {
  font-family: 'Museo Sans', sans-serif;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .metric-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #333;
    margin-bottom: 8px;
    padding: 10px 12px;
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    transition: color 200ms ease-in-out;

    @if $isTab {
      flex: 1 0 24%;
      text-align: center;
    }

    &.selected {
      border-radius: 4px;
      box-shadow: inset 0 1px 4px 0 $metric-primary-color;
      background-color: #f8fdff;
      color: $metric-primary-color;
      font-weight: bold;
    }

    &:not(.selected) {
      > div > span {
        border-bottom: 1px solid #9ba8b4;
        line-height: 1;
        transition: border-bottom 200ms ease-in-out;
      }

      &.disabled {
        pointer-events: none;
        color: #cfcfcf;

        > div > span {
          border-bottom: none;
        }
      }

      &:hover {
        cursor: pointer;
        color: $metric-primary-color;

        > div > span {
          border-bottom: 1px solid rgba($metric-primary-color, 0.5);
        }
      }

      &.in-process {
        &:hover {
          cursor: wait;
          color: $text-light-gray;
        }
      }
    }
  }
}

@mixin loader-before-paddings {
  .loader {
    &:before {
      left: -15px;
      right: -15px;
    }
  }
}

@mixin disable-select {
  ::ng-deep * {
    user-select: none;
  }
}

/// Applies media query to passed content according to specific breakpoint
/// @param {string} $size
/// breakpoint alias ("Small", "Medium", "Large" etc.)
@mixin for-screen-size ($size) {
  @if map-has-key($breakpoints, $size) {
    $mq-media-query: 'screen and' + map.get($breakpoints, $size);
    @media #{$mq-media-query} {
      @content;
    }
  }
}

@mixin bars-icon-mixin(
  $bar-width: 22px,
  $bar-height: 2px,
  $bar-color: #c5c7c9
) {
  position: relative;
  margin-top: 8px;
  vertical-align: middle;
  cursor: pointer;
  left: 1em;
  background-color: transparent;
  background-image: none;
  outline: none;
  border: 0;

  > .icon-bar {
    display: block;
    width: $bar-width;
    height: $bar-height;
    border-radius: 1px;
    background-color: $bar-color;
    margin-top: 4px;
    transition: width 0.7s ease;
  }

  &.icon-showed {
    &:after {
      @extend .icon-collapse-highlight;
    }
  }

  &.icon-collapsed {
    &:before {
      @extend .icon-collapse-highlight;
    }

    > .icon-bar {
      &:nth-child(2) {
        transition: width 0.7s ease;
        width: 66.6%;
      }

      &:nth-child(3) {
        transition: width 0.7s ease;
        width: 33.3%;
      }
    }
  }
}

@mixin before-hover($color: #00c872, $margin-right: 5px) {
  &.selected,
  &:hover {
    > span::before {
      background-color: $color;
    }
  }
  &.selected {
    font-weight: bold;
  }

  > span::before {
    content: '';
    display: inline-block;
    border-radius: 1.5px;
    background-color: transparent;
    width: 3px;
    vertical-align: bottom;
    height: 20px;
    margin-right: $margin-right;
  }
}

@mixin hover-effect {
  &:hover {
    background-color: rgba(211, 211, 211, 0.4);
  }
}

@mixin badge-content {
  display: inline-block;
  height: 24px;
  min-width: 32px;
  border-radius: 40px;
  padding: 2px 8px;
  background-color: #eeeeee;
  text-align: center;
  cursor: pointer;

  &.no-clickable {
    cursor: default;
  }
}
