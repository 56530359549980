se-form-input:not(.ng-invalid).policy-in-force-records-search,
se-form-input:not(.ng-invalid).custom-page-history-records-search,
se-form-input:not(.ng-invalid).custom-page-search,
se-form-input:not(.ng-invalid).sales-concept-search,
se-form-input:not(.ng-invalid).custom-page-presentation-list-search {
  .input-wrapper.search input.form-control  {
    border: 1px solid #B5BDC5;
    box-shadow: inset 0 1px 4px #9ba8b473;
    border-radius: 4px;

    &:focus, &:active, &:hover {
      border: 1px solid #B5BDC5;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      color: #9BA8B4;
    }
  }

  .search-icon {
    &.ensight-icon-search,
    &.ensight-icon-clear {
      color: black;
    }
  }
}
