.aapp-ret-src-modal {
  padding: 40px 40px 20px;
  border-radius: 2px;

  .ret-src-label,
  .ret-src-title {
    line-height: 20px;
  }

  .ret-src-label {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a3b3c3;
  }

  .ret-src-title {
    font-family: 'Museo Sans';
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #444950;
  }

  .ret-src-input {
    width: 220px;
    margin-top: 3px;
    line-height: 17px;
    border: none;
    border-bottom: 1px solid rgba(179, 195, 213, 0.7);
    letter-spacing: 0.5px;
    color: #444950;

    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: rgba(235, 235, 228, 0);
    }
  }

  .ret-src-input::-webkit-input-placeholder {
    line-height: 17px;
    font-family: 'Museo Sans';
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #b8c5d1;
  }

  .ret-src-input:-moz-placeholder {
    line-height: 17px;
    font-family: 'Museo Sans';
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #b8c5d1;
  }

  .ret-src-input::-moz-placeholder {
    line-height: 17px;
    font-family: 'Museo Sans';
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #b8c5d1;
  }

  .ret-src-input:-ms-input-placeholder {
    line-height: 17px;
    font-family: 'Museo Sans';
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #b8c5d1;
  }

  .ret-src-btn {
    line-height: 17px;
    padding: 0;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: #b8c5d1;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  .ret-src-header {
    .ret-src-cog {
      float: left;
      margin-top: 4px;
      font-size: 30px;
      color: #88a3bf;
    }

    .ret-src-title-box {
      height: 40px;
      margin-left: 40px;

      .ret-src-circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        vertical-align: middle;
        margin-left: 5px;
        border-radius: 50%;
      }
    }

    .ret-src-close {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 25px;
      right: 25px;
      overflow: hidden;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #b0c2d5;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .ret-src-body {
    .ret-src-text {
      line-height: 17px;
      margin-top: 15px;
      letter-spacing: 0.5px;
      color: #b8c5d1;
    }

    .ret-src-block {
      margin-top: 30px;
      color: #b8c5d1;

      .ret-src-title {
        font-size: 14px;
      }

      .ret-src-label {
        margin: 10px 0 0;
        line-height: 17px;
        letter-spacing: 0.4px;
        color: #b8c5d1;

        > span.ret-input-addon-income {
          position: relative;
          pointer-events: none;
          z-index: 9;
          color: #444950;

          + input.ret-src-input {
            padding-left: 1em;
            transform: translateX(-7px);
          }
        }
      }

      span.ret-src-label {
        display: inline-block;
        margin-top: 20px;
      }

      assurance-slider {
        & ~ .ret-src-input {
          width: 50px;
          margin-left: 10px;
          text-align: right;
          vertical-align: super;
          padding-right: 0.6em;

          + .ret-input-addon {
            position: relative;
            top: -0.3em;
            left: -0.8em;
            pointer-events: none;
            color: #444950;
          }
        }
      }

      .rzslider {
        width: 200px;
        margin-top: 10px;

        & ~ .ret-src-input {
          width: 50px;
          //line-height: 17px;
          margin-left: 10px;
          text-align: right;
          vertical-align: super;
          //letter-spacing: 0.5px;
          //color: #444950;
        }

        .rz-bar-wrapper {
          .rz-bar {
            height: 6px;
            border-radius: 4px;
            background-color: rgba(179, 195, 213, 0.7);
          }

          .rz-selection {
            background-color: #6a8aae;
          }
        }

        .rz-pointer-min {
          width: 20px;
          height: 20px;
          top: -7px;
          border: 1px solid #fff;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          background-color: #6a8aae;

          &:after {
            display: none;
          }

          &.rz-active {
            outline: none;
          }
        }
      }
    }
  }

  .ret-src-footer {
    margin-top: 40px;

    .ret-src-btn-save {
      width: 60px;
      height: 60px;
      position: absolute;
      right: -7px;
      bottom: -7px;
      padding: 0;
      border: none;
      border-radius: 2px;
      background-color: #c9d5e1;

      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        width: 20px;
        height: 35px;
        position: absolute;
        top: 6px;
        left: 20px;
        border: 1px solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
      }
    }
  }
}

.configs-theme {
  .modal-content {
    padding-left: 0;
    padding-right: 0;
  }

  .assurance-slider {
    width: 200px;
    float: left;
    margin-top: 10px;

    + .ret-src-input {
      width: 220px;
      margin-top: 3px;
      margin-left: 10px;
      line-height: 17px;
      border: none;
      border-bottom: 1px solid rgba(179, 195, 213, 0.7);
      letter-spacing: 0.5px;
      color: #444950;
      text-align: center !important;
      width: 50px;
      vertical-align: super;
      padding-right: 0.6em;

      &:focus {
        outline: none;
      }
      &:disabled {
        background-color: rgba(235, 235, 228, 0);
      }
    }

    .assurance-range-slider {
      -webkit-appearance: none;
      height: 6px;
      border-radius: 5px;
      background: #c9d5e1;
      outline: none;
      padding: 0;
      margin: 0;

      &.disabled {
        pointer-events: none;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
        background: #6a8aae;
        cursor: pointer;
        transition: background 0.15s ease-in-out;

        &:hover {
          background: darken(#6a8aae, 5%);
        }
      }

      &:active::-webkit-slider-thumb {
        background: darken(#6a8aae, 5%);
      }

      &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
        background: #6a8aae;
        cursor: pointer;
        transition: background 0.15s ease-in-out;

        &:hover {
          background: darken(#6a8aae, 5%);
        }
      }

      &:active::-moz-range-thumb {
        background: darken(#6a8aae, 5%);
      }

      &::-moz-range-progress {
        background: #6a8aae;
        height: 6px;
        border-radius: 5px;
        outline: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .ret-input-addon {
    position: relative;
    top: -0.3em;
    left: -0.8em;
    pointer-events: none;
    color: #444950;
  }

  .ret-input-addon-before {
    position: relative;
    left: 0.8em;
    pointer-events: none;
    color: #444950;
    margin-left: -0.8em;

    & + .ret-src-input {
      padding-left: 0.8em;
    }
  }
  .ret-src-block.ret-src-block {
    margin-bottom: 40px;
  }
}
