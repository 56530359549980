@import 'variables';
@import 'text-placeholder';

%shared-details-main-container {
  .content-section {
    padding: 0 2.5em;
    transition: all 1s ease;
    background-color: white;

    &.page-p-15 {
      padding: 0 15px;
    }

    .top-btns-block {
      float: right;
    }
    .download-pdf {
      font-size: 20px;
      display: inline-block;
      color: #0e6db4;

      &:hover {
        cursor: pointer;
        color: gray;
      }
    }
    &.collapsed {
      transition: all 1s ease;
      width: 100%;
      padding-left: 60px;
    }
    .wrapper {
      position: relative;
      margin-top: 0;
    }
    .monthly-chart {
      .wrapper {
        min-height: auto;
      }
    }
    .content-title {
      color: #05a74f;
      hr {
        padding: 0px;
      }
    }
    .service-btns {
      text-align: right;
      margin-top: 15px;
      position: static;
      span {
        margin-right: 5px;
      }
    }
    .content-article {
      margin-top: 30px;
      .article-title {
        padding: 0 15px;
      }

      & > div {
        color: #333;
      }
    }
  }

  .custom-page-content-section {
    min-height: calc(100vh - #{$preview-navbar-height});
  }

  .content-footer {
    padding: 20px;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
  }

  .light-gray-label {
    font-family: 'Museo Sans';
    color: $text-light-gray;
    font-size: 13px;
  }

  .setup {
    .content-article {
      text-align: justify;
      padding: 0 15px;
    }
    .content-title {
      color: $text-gray;
      margin-bottom: 20px;
      text-align: right;

      button {
        margin-bottom: 10px;
        float: right;
      }

      .add-to-presentation-ckeckbox {
        clear: right;
      }
    }
    .name-section {
      display: inline-block;
      width: 100%;
      padding: 15px;
      transition: margin-left 0.7s ease, opacity 0.7s ease;
    }
    .selected-plans {
      padding: 0 5px;
      margin-bottom: 40px;
    }
    .chart-block:last-child {
      background-position: center center;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
    .chart-block .chart-view-btn {
      position: absolute;
      right: 0px;
      text-decoration: none;

      i {
        color: #b8c5d1;
      }
    }
  }
  .aapp-presentation-header-logo {
    height: 50px;
    width: 120px;
    display: inline-block;
    float: left;
    margin: -6px 0px -10px;
    padding: 4px 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: contain;
    -webkit-background-repeat: no-repeat;
    -webkit-background-position: center;
  }
}

.popover-revision {
  font-family: 'Roboto';
  > .arrow {
    display: none;
  }
  > .popover-inner {
    > .popover-title {
      padding: 1em;
      background-color: #ffffff;
    }
    > .popover-content {
      padding: 0;
      > .revision-body {
        min-width: 270px;
        background-color: #f7f8fa;
        > div {
          padding: 1em;
          border-bottom: 1px solid #e1e2e4;
        }
      }
      > .revision-button {
        padding: 1em;
        text-align: center;
        > button {
          font-size: 12px;
          padding: 1em 2em;
        }
      }
    }
    > .popover-title {
      text-transform: uppercase;
    }
  }
}

.content-section .aapp-switch-chart {
  position: relative;
  top: 63px;
  left: 20px;
}

.policy-year-selector {
  position: relative;
  right: 2em;
  top: -0.5em;

  > span:first-child {
    font-family: 'Museo Sans';
    color: #c3d1e1;
  }

  > .policy-option {
    font-family: 'Museo Sans';
    font-weight: 900;
    color: #c3d1e1;

    &.select-policy {
      font-weight: bold;
      color: #7693b5;
    }
  }

  > .aapp-switch {
    position: relative;
    top: 3.9em;
    right: 0;
  }
}

.aapp-switch {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  right: 50px;
  transform: translateY(-2.7em);

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  > input {
    display: none;
    &:checked + .slider {
      background-color: $checkbox-background-checked;
      color: $checkbox-background-checked;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #6485a9;
    }
    &:checked + .slider:before {
      background-color: #ffffff;
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .no-icon {
    &:before {
      content: '';
      padding: 3px 0 0 4px;
      font-size: 18px;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $checkbox-background-unchecked;
    color: $checkbox-background-unchecked;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background: #ffffff;
      -webkit-transition: transform 0.4s;
      transition: transform 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}

.new-presentation-wrapper {
  background-color: #fff;
  .container-full {
    margin: 0 auto;
    width: 100%;
  }
  .new-presentation-main-container {
    .details-main-container {
      @extend %shared-details-main-container;
    }
    .preview {
      &.content-section {
        background-color: #eaeaea;
        padding: 0;
        .content-article {
          text-align: justify;
          margin-top: 10px;
          .content-article-wrapper {
            background-color: #ffffff;
            padding: 50px 80px;
            min-height: 100vh;

            .content-section {
              margin: 2em 0;
            }
          }

          & > div {
            color: #333;
          }
        }
        .content-title {
          padding: 10px 90px;
        }
      }
    }
  }
}

.add-plan {
  margin-left: 20px;
  color: #a29e9e;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}

.a-app-alert {
  min-width: 30%;
  left: 50%;
  top: 0px;
  position: fixed;
  transform: translate(-50%, -80px);
  transition: all 1s ease;
  z-index: 99999;
}

.details-header {
  display: table;
  position: fixed;
  top: 0px;
  z-index: 1002;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
  .new-presentation-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: $primary-color;
    color: #ffffff;

    &.is-template {
      background-image: linear-gradient(to left, #ffc200, #ff7c00);
    }

    > img {
      position: relative;
      transform: scale(1.2);
      margin-right: 1.5em;
      height: 3em;
      width: auto;
      float: left;
    }
    button {
      color: inherit;
      text-decoration: none;
      font-size: 2.3em;
      border-left: solid 1px rgba(255, 255, 255, 0.2);
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  .new-presentation-info {
    > a {
      margin-top: 0.35em;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
      > span {
        margin-right: 0.5em;
      }
      + .popover {
        @extend .popover-revision;
      }
    }
    padding: 10px 20px;
    background: $side-bar-color;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    h4 {
      margin-right: 120px;
    }
    & > div {
      display: inline-block;
      margin: 10px 30px;
      .item-title {
        margin-right: 10px;
      }
    }
  }
}
