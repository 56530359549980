.collapsed-style-editor .presentation-view-wrapper .content-section {
  margin-left: 8%;
  padding-right: 10% !important;
}

.cover-letter-preview {
  .name-section {
    a.pp-action-button-link.a-app-action-buttons-color {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.name-section {
  a.pp-action-button-link.a-app-action-buttons-color {
    margin-top: -10px;
    margin-right: 15px;
  }
}
