@import 'variables';

._action-button-link {
  font: 300 15px 'Museo Sans';
  outline: none;

  [class^='ensight-icon-'],
  [class*=' ensight-icon-'] {
    position: relative;
    color: $light-link-color;
    margin-right: 5px;
    font-size: 20px;
    top: 5px;
  }

  a,
  label {
    margin-top: 0.35em;

    > span {
      margin-right: 0.5em;
    }
  }
}

.pp-action-button-link {
  color: #0e6db4;
  @extend ._action-button-link;
}

.content-title {
  > div > h3 {
    font-family: 'Museo Sans';
  }
  > div {
    @extend ._action-button-link;
  }
}

.header-plan {
  margin-bottom: 1em;
  @extend ._action-button-link;

  > h4 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
}
