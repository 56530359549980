.bs-datepicker {
  &.date-picker-version {
    .bs-datepicker-head {
      background-color: #00a7f7 ;
    }

    .bs-datepicker-body {
      table {
        td {
          span.selected,
          &.selected span,
          span[class*='select-']:after,
          &[class*='select-'] span:after {
            background-color: #00a7f7 ;
          }

          &.week span {
            color: #00a7f7 ;
          }
        }
      }
    }
  }
}
