@import "mixins";

.ep-action-link {
  position: relative;

  > .ep-action-message {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    color: #000;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 3px 20px;
    border-radius: 3px;
    white-space: nowrap;
  }
}

.btn-group-infinity .btn-group.se-btn-group {
  se-button.group-btn:not(.last) button {
    border-right: 1px solid hsl(200,100%,39%);
    margin-bottom: 3px;

    &:hover,
    &:active,
    &:focus {
      border-right: 1px solid hsl(200,100%,39%);
    }
  }
}

.c3Chart.c3.tooltip-value-hide .c3-tooltip td.value {
  display: none;
}


.container-menu-custom-page, .container-menu-config-file, .insert-section-item {
  position: relative;
  margin-left: 20px;

  .btn-menu {
    @include flex-container($flex-direction: column, $align-items: center,);
    width: 24px;
    height: 24px;
    background-position: center;
    background-image: url('/assets/icons/kebab.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
    position: relative;
    background-color: transparent;
  }

  .menu {
    @include flex-container($flex-direction: column, $align-items: center, $justify-content: center);
    position: absolute;
    min-width: 230px;
    background-color: white;
    box-shadow: 0 2px 6px 0 #00000040;
    right: 0;
    top: 40px;

    .btn-menu-item {
      border: none;
      display: block;
      width: 100%;
      text-align: left;
      font-size: 18px;
      padding: 6px 10px;
      background-color: white;
      position: relative;
    }
  }
}

.aapp-header-logo {
  padding: 4px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  -webkit-background-size: contain;
  -webkit-background-repeat: no-repeat;
  -webkit-background-position: left;
  background-image: $app-logo-img;
  height: 65px !important;
  margin-top: 15px;
}

.cdk-overlay-container {
  z-index: 1025;
}
