.aapp-home-grid-container {
  margin-top: 35px;

  .aapp-home-grid-box {
    border: 1px solid #dae2e6;
    border-radius: 0;
    box-shadow: none;

    .aapp-home-card-heading,
    .aapp-home-card-footer {
      border-color: #dae2e6;
      background: none;
    }

    .aapp-home-card-heading {
      .aapp-home-grid-title {
        margin: 15px 0 13px;
        text-transform: uppercase;
      }

      .aapp-home-input-box {
        position: relative;

        .view-by-dropdown {
          margin-top: 7px;

          > span {
            position: absolute;
            top: 8px;
            left: -45px;
            font-weight: bold;
          }
        }

        .aapp-dropdown-box {
          width: 140px;
          float: right;
          position: relative;
          margin-top: 7px;
          margin-right: -15px;

          .aapp-dropdown-btn {
            width: 100%;
            position: relative;
            border: 1px solid #ccc;
            border-radius: 0;
            background: none;
            text-align: left;

            .aapp-dropdown-icon {
              position: absolute;
              top: 9px;
              right: 7px;
            }
          }

          .aapp-dropdown-menu {
            width: 100%;
            position: absolute;
            margin: 0;
            padding: 0;
            z-index: 2;
            background: #fff;
            border: 1px solid #ccc;
            list-style: none;

            .aapp-dropdown-item {
              padding: 5px 13px;
              cursor: pointer;

              &:hover {
                background: #eaeaea;
              }
            }
          }
        }

        .aapp-dropdown-label {
          float: right;
          margin: 16px 10px 0 0;
        }

        .aapp-home-input {
          width: 100%;
          height: 34px;
          margin-top: 7px;
          padding-left: 34px;
          font-size: 14px;
        }

        .aapp-home-search-icon {
          position: absolute;
          top: 15px;
          left: 25px;
          font-size: 18px;
          color: #999;
        }
      }
    }

    .aapp-home-card-footer {
      padding-top: 20px;
      padding-bottom: 20px;

      .aapp-btn-arrow {
        margin: 0;
        padding: 0;
        border: 0;

        &:hover {
          background: none;
        }

        &:focus {
          outline: none;
          background: none;
          box-shadow: none;
        }

        &:active {
          box-shadow: none;
        }

        &.aapp-btn-arrow-left {
          margin: 0 15px 0 20px;
        }
      }
    }

    table {
      table-layout: fixed;
      width: calc(100% - 30px);
      margin: 0 15px;

      .aapp-home-row {
        cursor: pointer;

        &:hover {
          background-color: $side-bar-color;
        }
      }

      tr:last-child td {
        border: none;
      }

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 3px solid $primary-color;
        text-transform: uppercase;
        font-weight: bold;
        color: #656e7b;

        &.aapp-home-header-fixed-row {
          width: 105px;
        }

        &.aapp-home-header-actions {
          text-align: center;
        }
      }

      td {
        padding-top: 12px;
        padding-bottom: 12px;
        border: none;
        border-bottom: 1px solid #dae2e6;

        &:nth-child(1),
        &:nth-child(2) {
          color: $primary-color;
        }

        .aapp-home-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.aapp-home-grid-action-row {
          position: relative;
          padding: 0;
          text-align: center;
          font-size: 16px;

          .aapp-home-grid-action {
            width: 28px;
            height: 44px;
            top: 0;
            color: #00a7f7;
            cursor: pointer;

            &:hover {
              color: lighten(#00a7f7, 15%);
            }

            &:before {
              position: absolute;
              top: calc(50% - 7px);
            }

            &.shared-with {
              color: darken(#00a7f7, 15%);

              &:hover {
                color: darken(#00a7f7, 20%);
              }
            }
          }
        }
      }

      .aapp-sort-icon {
        top: 3px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
