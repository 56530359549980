@import '../../app/common/shared/components/assurance-navbar/navbar.component';

.common-container {
  height: 100%;
  width: 100%;
  transition: width 1s ease;
  &.collapsed-style-editor {
    width: 83%;
    transition: width 1s ease;
    .inner-container.collapsed-container {
      margin-left: 30%;
    }
  }
}
.side-bar {
  .loader {
    a {
      pointer-events: none;
    }
    &::after,
    &::before {
      position: fixed;
    }
  }
  &.style-editor-bar {
    right: 0;
    top: 0;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
    transition: all 1s ease;
    border-left: 1px solid #d9d9d9;
    width: 17%;
    padding: 0px;
    .tab-container {
      display: block;
      height: 100%;
      > .nav-tabs {
        .nav-link {
          color: $link-color;
        }
      }
      .tab-content {
        height: inherit;
        overflow: auto;
      }
    }
    section {
      height: inherit;
      overflow: auto;
    }
    .collapse-btn {
      left: -25px;
      right: auto;
      transform: translate(50%, -50%);
      transition: all 1s ease;
      > a {
        > .fa-chevron-right {
          margin-left: 3px;
        }

        > .fa {
          color: $link-color;
        }
      }
    }
    &.collapsed-left {
      transform: translate(100%, 0%);
      transition: all 1s ease;
      .collapse-btn {
        transform: translate(0px, -50%);
        transition: all 1s ease;
      }
    }
    .style-editor-article {
      .a-app-themes-search-label {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      }
      .accordion-toggle {
        outline: 0;
      }
      .redirect-icon {
        margin-left: 15px;
      }
      .chevron-icon-right {
        display: block;
      }
      .chevron-icon-bottom {
        display: none;
      }
      .panel-collapse {
        .chevron-icon-right {
          display: none;
        }
        .chevron-icon-bottom {
          display: block;
        }
      }
      .style-editor-block {
        .themes-service-icons {
          margin: 10px 3px;
          font-size: 1.2em;
          &:hover {
            cursor: pointer;
          }
        }
        .style-editor-block-item {
          padding: 1px 10px;
          > .option {
            margin: 10px 0;
            font-size: 0.8rem;
          }

          &.blocked {
            display: inline-block;
            width: 100%;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
          .theme-wrapper {
            display: flex;
            justify-content: space-between;
            margin: {
              right: -10px;
              top: 10px;
              bottom: 10px;
            }
          }
          .style-editor-color-picker {
            height: 15px;
            width: 15px;
            display: inline-block;
            border-radius: 50%;
            border: solid 1px rgba(0, 0, 0, 0.2);
            &:hover {
              cursor: pointer;
            }
          }
          .color-picker {
            position: absolute !important;
            left: 30px !important;
            .a-app-cp-cancel-button-class {
              padding: 10px;
              font-size: 2em;
              color: #ffffff;
              background: #c9d5e1;
              border: none;
              width: 40px;
              height: 40px;
              position: absolute;
              bottom: 0;
              right: 0;
              transform: translate(30%, 30%);
            }
          }
          .highlight-crosshairs,
          .restore-icon {
            padding: 5px;
            text-decoration: none;
            color: rgb(184, 197, 209);
          }
          .logo-uploader-input {
            display: none;
          }
          .a-app-logo-uploader-label {
            font-size: 1.4em;
            &:hover {
              cursor: pointer;
            }
          }
          .a-app-welcome-text {
            width: 100%;
            #a-app-welcome-text {
              resize: none;
              width: 100%;
            }
          }
          .theme-apply-id {
            color: #03c303;
            &:hover {
              cursor: default;
            }
          }
        }
      }
    }
    .a-app-show-styles-list {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
    .card {
      position: static;
      border-radius: 0;
      border-color: rgba(255, 255, 255, 0);
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      .panel-collapse {
        & > .card-body {
          padding: 0;
        }
      }
    }

    .styles-service-btns-wrapper {
      height: auto;
      padding-bottom: 60px;
      padding-top: 20px;
      > .btn-primary {
        background-color: $link-color;
      }
      .import-style-btn {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .a-app-theme-title-label {
      .fa-pencil:before {
        color: red;
      }
    }

    label.a-app-theme-title-label,
    label.a-app-themes-search-label {
      font-weight: normal;
      position: relative;
      font-size: 1.2em;
      width: 100%;
      .a-app-theme-title {
        width: inherit;
        border: none;
        padding: 10px 10px;
      }
      &.invalid {
        span {
          color: rgba(255, 0, 0, 0.9);
        }
      }
      span {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .all-themes-accordion {
      .theme-logo-preview-link {
        display: flex;
        align-items: center;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $link-color;
        font-size: 0.8rem;

        .theme-logo-preview {
          height: 35px;
          margin-right: 5px;
          max-width: 80px;
        }
      }
    }
    .nav-item {
      width: 50%;
    }
  }
}
.alerts-container {
  margin-top: -60px;
  .a-app-alert {
    width: 100%;
  }
}

.a-app-logo-img {
  height: 80px;
}

.highliter {
  position: absolute;
  background: #ff0000;
  opacity: 0;
  animation-name: attenuation;
  animation-duration: 4s;
  z-index: 999;
}

@keyframes attenuation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.theme-remove-modal {
  .agencies-block {
    display: block;
    margin-top: 15px;
  }
  .modal-body {
    word-wrap: break-word;
  }
}
