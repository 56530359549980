@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    page-break-after: always;
  }

  img {
    display: block;
    page-break-inside: avoid;
    -webkit-region-break-inside: avoid;
  }

  .print-elem-hide {
    display: none !important;
  }

  #rendered {
    display: none;
  }

  /*
    Need to replace `display: flex` to `display: block` from `custom-page-wrapper` selector because of img cut off.
    `page-break-inside: avoid` doesn't work in specific cases.
  */
  .custom-page-wrapper {
    display: block !important;
  }
}
