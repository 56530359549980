:root {
  --accent: #006bb6;
  --accent-h: 205;
  --accent-s: 100%;
  --accent-l: 36%;
  --accent-contrast: #ffffff;
  --accent-contrast-h: 0;
  --accent-contrast-s: 0%;
  --accent-contrast-l: 100%;
  --primary: #00a7f7 !important;
  --primary-h: 200;
  --primary-s: 100%;
  --primary-l: 49%;
  --primary-contrast: #ffffff;
  --primary-contrast-h: 0;
  --primary-contrast-s: 0%;
  --primary-contrast-l: 100%;
  --primary-foreground: #2e3644;
  --primary-foreground-h: 219;
  --primary-foreground-s: 20%;
  --primary-foreground-l: 23%;
  --secondary-foreground: #9ba8b4;
  --secondary-foreground-h: 209;
  --secondary-foreground-s: 15%;
  --secondary-foreground-l: 66%;
}
