.ensight-icon-agency,
.ensight-icon-revisions,
.ensight-icon-roles,
.ensight-icon-settings-2,
.ensight-icon-users-2,
.ensight-icon-custom-pages,
.ensight-icon-settings-3 {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ensight-icon-manage-password {
  background-image: url('/assets/icons/password.svg');
}
