@import 'variables';

.aapp-input-details {
  margin-bottom: 40px;
  font-size: 28px;
  color: #6a8aae;
  font-family: 'Museo Sans';

  .aapp-input {
    margin-right: 5px;
    border: none;
    border-bottom: 2px dotted #adb3b9;
    font-size: 28px;
    background-color: transparent;
    color: #000;
    font-weight: bold;
    font-family: 'Museo Sans';
    padding-bottom: 0.1em;

    &:focus {
      outline: none;
      border-bottom: 2px dotted #adb3b9;
    }

    &.aapp-currency-input {
      width: 5.5em;
    }

    &.aapp-number-input {
      width: 3em;
    }
  }

  .aapp-dropdown-box {
    display: inline-block;
    width: 6em;
    position: relative;
    margin-right: 5px;

    .aapp-dropdown-btn {
      height: 28px;
      width: 100%;
      position: relative;
      padding: 0 0 1.2em 0;
      border: none;
      border-bottom: 2px dotted #adb3b9;
      border-radius: 0;
      background-color: transparent;
      font-size: 28px;
      text-align: left;
      font-weight: bold;
      font-family: 'Museo Sans';

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .aapp-selected-period {
        position: relative;
        top: -4px;
      }

      .aapp-dropdown-icon {
        top: -3px;
        font-size: 12px;
        vertical-align: 50%;
      }
    }

    .aapp-dropdown-menu {
      position: absolute;
      z-index: 99;
      list-style: none;
      padding: 0;
      background: #edf3fa;
      border: 1px solid grey;
      width: 100%;
      top: 35px;
      color: #000;
      font-size: 21px;
      font-weight: bold;

      .aapp-dropdown-item {
        cursor: pointer;
        padding: 0.3em 0.1em;

        &:hover {
          background-color: darken(#edf3fa, 5%);
        }
      }
    }
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.no-chart-data-cash {
  font-size: 16px;
  font-family: 'Museo Sans';
  font-weight: bold;
  color: #b92626;
  position: absolute;
  left: 2.6em;
}

.no-chart-data-retirement {
  font-size: 18px;
  font-family: 'Museo Sans';
  font-weight: 700;
  color: #000000;
  width: 100%;
  text-align: center;
  height: 500px;
  position: relative;
}

.no-chart-data-retirement span {
  top: 50%;
  position: absolute;
  margin-left: -65px;
}

.prev-presentation-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.4em;
  left: 0.4em;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 1.6em;
  border-radius: 50%;
  background-color: lighten(#b8c5d1, 12%);

  &:hover {
    background-color: #b8c5d1;
    padding: 0.1em;
    border-radius: 2em;
    transition: background-color 300ms linear;
  }

  &:before {
    margin-right: 2px;
  }

  & ~ .name-section {
    margin-left: 2em;
  }
}

@mixin smooth-screen($font-size) {
  position: relative;
  z-index: 990;

  &::after,
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  %text-style {
    top: calc(100% / 3);
    text-align: center;
    color: #fff;
    font-size: $font-size;
    padding: 0 10%;
    z-index: 10001;
  }

  > div.smooth-screen-content {
    position: absolute;
    width: 100%;
    @extend %text-style;
  }

  &::before {
    background-color: #656e7b;
    z-index: 10000;
    opacity: 0.7;
  }
}

[ensightSmoothScreen] {
  &.smooth-screen {
    @include smooth-screen(28px);
  }

  &.custom-page-smooth-screen {
    @include smooth-screen(32px);


    ensight-spreadsheet-table tbody {
      max-height: 400px;
    }

    > *:not(.smooth-screen-content) {
      visibility: hidden;

      .chart-block {
        opacity: 0;
      }
    }
  }

}

.setup-container {
  display: flow-root;
}

.collapsed-container {
  margin-left: 25%;

  & .content-section > article:not(:first-child) {
    transition: margin-left 0.7s ease, opacity 0.7s ease;
  }
}

.collapsed-view-container {
  margin-left: 25%;

  .content-section [export-pdf],
  .content-section article.grid-block,
  .content-section article.name-section, .top-name-section,
  .content-section article.placeholder-container:not(.disclosure-text),
  .content-section article.chart-block.chart-charges,
  .content-section article.selected-plans.plans-charges,
  .content-section .sale-concept-body {
    transition: margin-left 0.7s ease, opacity 0.7s ease;
    margin-left: -35%;
  }
}

.nvtooltip {
  display: none;
}
