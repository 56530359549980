@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select {
  .ng-select-container {
    .ng-value-container {
      padding: 0 8px;

      .ng-placeholder {
        display: inline;
      }

      .ng-placeholder,
      .ng-value {
        color: #333;
        font-weight: 600;
      }
    }

    &.ng-has-value .ng-placeholder {
      display: none;
    }
  }

  &.ng-select-filtered
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
    display: none;
  }

  &.ng-select-version,
  &.ng-select-location {
    .ng-select-container {
      border: 1px solid rgb(133, 133, 133);
    }

    .ng-dropdown-panel-items.scroll-host {
      max-height: 178px;
      overflow-y: scroll;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: 31px;
      min-height: 31px;
    }

    &.ng-select-location  {
      .ng-select-container {
        height: 36px;
        min-height: 36px;
      }
    }
  }
}

